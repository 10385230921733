<template>
  <van-empty :description="description" :image="require('@/assets/images/empty.png')">
  </van-empty>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    description: {
      type: String,
      default: '无数据'
    }
  },
  methods: {
    reatyClickHandle() {
      this.$emit('reaty')
    }
  }
}
</script>

<style lang="scss" scoped>
  .bottom-button {
    width: 160px;
    height: 40px;
  }
</style>